.App {
  text-align: center;
  position: relative;
  min-height: 100vh;
}

.content-wrap {
  padding-bottom: 2.5rem;
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header{
    width: 100%;
    height: 100%;
    color: purple;
    margin: 0;
  }

  .title {
    margin: 0;
  }

  .subtitle {
    color: rgb(161, 4, 161);
    margin: 0;
  }

  hr {
    margin: 0;
  }
  
    .bold {
      font-weight: 500;
      width: 100%;
      height: 100%;
    }

  .coming-soon {
    color: rgb(240, 0, 0);
    font-size: 35px;
    height: 800px;
    margin: 0;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }

.coming-soon-text {
  width: 100%;
  margin-top: 150px;
  background-color: rgba(255, 255, 255, 0.05);
}

  #footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    margin-top: 0;
  }

  .copyright {
    margin-bottom: 10px;
  }